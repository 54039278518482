body {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
    linear-gradient(98.44deg, #3e5ea9 1.92%, #7eedba 122.04%) !important;
}

/*ADD SELF HOST FONTS */
@font-face {
  font-family: "Circular Std Medium";
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url("assets/fonts/Circular-Std-Medium.woff2") format("woff2");
}

@font-face {
  font-family: "Euclid Circular";
  font-display: swap;
  src: url("assets/fonts/EuclidCircularA-Regular.woff2") format("woff2");
}

@font-face {
  font-family: "Euclid Circular";
  font-display: swap;
  font-weight: 300;
  src: url("assets/fonts/EuclidCircularA-Light.woff2") format("woff2");
}

@font-face {
  font-family: "Euclid Circular";
  font-display: swap;
  font-weight: 200;
  src: url("assets/fonts/EuclidCircularA-Medium.woff2") format("woff2");
}

@font-face {
  font-family: "Euclid Circular";
  font-display: swap;
  font-weight: 600;
  src: url("assets/fonts/EuclidCircularA-SemiBold.woff2") format("woff2");
}

@font-face {
  font-family: "Euclid Circular";
  font-display: swap;
  font-weight: 700;
  src: url("assets/fonts/EuclidCircularA-Bold.woff2") format("woff2");
}

@font-face {
  font-family: "Euclid Circular";
  font-display: swap;
  font-style: italic;
  src: url("assets/fonts/EuclidCircularA-Italic.woff2") format("woff2");
}

@font-face {
  font-family: "Euclid Circular";
  font-display: swap;
  font-weight: 200;
  font-style: italic;
  src: url("assets/fonts/EuclidCircularA-MediumItalic.woff2") format("woff2");
}

*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html,
body {
  font-size: 62.5%;
  font-family: "Euclid Circular", sans-serif;
  scroll-behavior: smooth;
}

.container {
  width: 100%;
  height: 100%;
  justify-content: center;
  margin: auto !important;
}
.secV {
  background-position: center;
  background-repeat: no-repeat;
  background-image: url(./assets/images/vec.png);
  background-size: cover;
  opacity: 0.05;
  width: 100%;

  height: 100.01%;
  position: absolute;
  background-repeat: round;
}

input::placeholder {
  color: #adadad !important;
  font-weight: 300 !important;
  font-size: 14px !important;
  font-family: 'Euclid Circular' !important;
}

h3 {
  font-style: normal;
  font-weight: 500 !important;
  font-size: 20px;
  line-height: 26px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #010101;
  white-space: no-wrap !important;
}

button {
  color: white ;
}
button.MuiPickersDay-root {
  color: black !important;
}

label {
  font-weight: 300 !important;
}
select option {
  font-weight: 300 !important;
}
input {
  height: 6rem;
  font-family: 'Euclid Circular' !important;
}

p.MuiTypography-root.MuiTypography-body1.MuiTypography-gutterBottom.css-1jfewiq-MuiTypography-root {
  margin: 0;
  font-size: 1.6rem;
  font-weight: 300 !important;
  line-height: 1.7;
  color: #4f4f4f;
  font-family: "Euclid Circular", "Circular Std Medium", Roboto, sans-serif;
  margin-bottom: 0.35em;
}

h5 {
  font-weight: 500 !important;
}

input {
  height: 6rem;
}
